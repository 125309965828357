import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
let OrgListModal = class OrgListModal extends Vue {
    constructor() {
        super(...arguments);
        this.showModal = false;
        this.loading = false;
        this.isEnableChild = true;
    }
    get modalTitle() {
        let title = '';
        if (this.isDelete) {
            title = '删除组织';
        }
        else {
            title = this.directStatus ? '启用组织' : '停用组织';
        }
        return title;
    }
    submit() {
        this.loading = true;
        this.$emit('confirm', {
            isEnableChild: this.isEnableChild,
            cb: (success) => {
                this.loading = false;
                if (success) {
                    this.cancel();
                }
            },
        });
    }
    cancel() {
        this.$emit('input', false);
    }
    async onValueChange(v) {
        this.showModal = v;
        if (v) {
            this.isEnableChild = true;
        }
    }
};
__decorate([
    Prop()
], OrgListModal.prototype, "value", void 0);
__decorate([
    Prop()
], OrgListModal.prototype, "isDelete", void 0);
__decorate([
    Prop()
], OrgListModal.prototype, "directStatus", void 0);
__decorate([
    Prop({
        default: () => {
            return [];
        },
    })
], OrgListModal.prototype, "subOrgNames", void 0);
__decorate([
    Watch('value')
], OrgListModal.prototype, "onValueChange", null);
OrgListModal = __decorate([
    Component({
        name: 'OrgListModal',
        components: {},
    })
], OrgListModal);
export default OrgListModal;
